import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"
import Lottie from "lottie-web"

import GScroll from "GScroll";

import {isMobile, simulateClick} from "utils";

export default class Transition
{
	constructor(elmt) {
		this.currentStep = 0;
        this.allSteps = 2; //3

        this.newClass;
        this.newTitle;
        this.newDOM;

        this.newFooter;

        this.animEnCours = true;
    }

    start (url) {

        this.animEnCours = true;

    	this.animationSortie();
    	this.changementDOM(url);
    }

    // STEP
    animationSortie() {

        clearInterval(linter)
        clearInterval(window.set)

        gsap.to('body', {
            backgroundColor:'#FFF',
            duration:1,
            ease:"power2.inOut"
        })
        gsap.to('#main', {
            opacity:0,
            duration:1,
            ease:"power2.inOut",
            onComplete: () => {
                this.incrementStep();
                this.killAll();
            }
        })   
        
    }

    killAll() {
        let triggers = ScrollTrigger.getAll();
        triggers.forEach( trigger => {
          trigger.kill();
        });
        document.querySelector('body').setAttribute('style','')
    }

    // STEP
    changementDOM(url) {
    	this.ajaxPromise(url).then((data) => {
            let parser = new DOMParser();
            let doc = parser.parseFromString(data, "text/html");
            let liste_class = doc.body.getAttribute('class');
            
            // maj titre de la page
            this.newTitle = doc.querySelector('title').text;
			this.newClass = doc.body.getAttribute('class');
            this.newDOM = doc.getElementById('main').innerHTML;
            this.newNav = doc.querySelector('header').innerHTML;

            this.newFooter = doc.querySelector('footer').innerHTML;

            // console.log(this.newNav);

            this.incrementStep();
  
        });

    }

    // ALL STEPS COMPLETE
    animationEntree(titre, classes, DOM) {
        //reset
        // console.log('entree');
        window.scrollTo(0,0);
        // if(!isMobile()){
        //     window.scroll.current = window.scroll.scrollTop = 0;
        // }

        this.animEnCours = false;
		this.currentStep = 0;

        if(window.firstCall){
            window.firstCall = false;

            document.querySelectorAll('#intro img')[0].classList.add('current')

            window.debut = setInterval(() => {
                let el = document.querySelector('#intro .current');
                el.classList.remove('current');
                // el.setAttribute('style', '');

                if(el.nextElementSibling != null){
                    el.nextElementSibling.classList.add('current');
                }else{
                    document.querySelectorAll('#intro img')[0].classList.add('current')
                }
            }, 200)

            gsap.fromTo('#intro img', {
                scale:0.7
            },{
                scale:1,
                duration:3,
                
                ease:"power3.inOut"
            })

            gsap.to('.innerLo', {
                opacity:1,
                duration:0.5,
                delay:2.5,
                ease:"power2.inOut"
            });

            gsap.to('#intro', {
                y:'-100%',
                delay:3,
                duration:1.5,
                ease:"power2.inOut"
            });

            gsap.to('.innerIn', {
                y:'100%',
                delay:3,
                duration:1.5,
                ease:"power2.inOut",
                onComplete:() => {
                    clearInterval(window.debut);
                    document.getElementById('intro').style.display = "none"
                }
            });

        }
        

    	// maj titre, class, DOM page
        document.title = this.newTitle;
		document.body.setAttribute('class', this.newClass);
        document.getElementById('main').innerHTML = this.newDOM;
        document.querySelector('header').innerHTML = this.newNav;
        document.querySelector('footer').innerHTML = this.newFooter;

        // gsap.fromTo('body', {backgroundColor:'#FFF'}, {backgroundColor:'#FFF', duration:1})

        gsap.to('#main', {
            opacity:1,
            duration:1,
            ease:"power2.inOut",
            onComplete:() => {
                // if(!isMobile()){
                //     window.scroll.resize();
                // }
                allAnimations();

            }
        })

        gsap.to('header', {
            duration:1,
            ease:"power2.inOut",
            opacity:1
        })

        if(document.querySelector('h1') != null){
            gsap.to('.innerSpan span', {
                duration:1.6,
                ease:"power4.out",
                stagger:0.06,
                delay:0.5,
                y:0
            })
        }
        
    }




    //
    //
    // util class
    //
    //
    incrementStep() {
    	this.currentStep++;
        // console.log(this.currentStep);
    	if(this.currentStep == this.allSteps) {
            // console.log('ça part');
    		this.animationEntree();
    	}
    }

    ajaxPromise (url) {
		return new Promise((resolve) => { //, reject
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.onload = () => resolve(xhr.responseText);
			// xhr.onerror = () => reject(xhr.statusText);
			xhr.send();
		});
	}
}










let linter;
function allAnimations() {
    //
    // ANIMATIONS ENTREE
    //


    initMagnetic();

    // global
    document.querySelectorAll('section[data-fond]').forEach(el => {
        gsap.to('body', {
            scrollTrigger: {
                trigger:el,
                start:'top 50%',
                end:'top 0%',
                scrub:true
            },
            backgroundColor: el.getAttribute('data-fond'),
            ease:'none',
            immediateRender:false
        })
    })
    document.querySelectorAll('.innerPhrase').forEach(el => {
        ScrollTrigger.create({
            trigger: el,
            start: "top bottom", 
            end: "bottom top",
            onEnter: () => {el.querySelector('.capital').classList.add('actif')},
            onLeave: () => {el.querySelector('.capital').classList.remove('actif')},
            onEnterBack: () => {el.querySelector('.capital').classList.add('actif')},
            onLeaveBack: () => {el.querySelector('.capital').classList.remove('actif')},
        })
    })

    // document.querySelectorAll('.aleatoire').forEach(el => {

    //     gsap.fromTo(el, {
    //         y: "-10%",
    //     },{
    //         y: "10%",
    //         scrollTrigger: {
    //             trigger:el,
    //             start:"top bottom",
    //             end:"bottom top",
    //             scrub:true
    //         }
    //     })
    // })

    // home
    if(document.body.classList.contains('home')){

        window.set = setInterval(() => {
            document.body.classList.add('simul');
            simulateClick(document.querySelector('button.right'));
        }, 5000)
        

        gsap.set('.currentSlide div', {
            scaleX: 1 / document.querySelectorAll('.lesImgs img').length
        })

        gsap.to('.phrase', {
            scrollTrigger: {
                trigger:'.phrase',
                start:'top bottom',
                end:'bottom top',
                scrub:true
            },
            rotate: -30,
            ease:'none'
        })

        // gsap.to('.heroImg', {
        //     scrollTrigger: {
        //         trigger:'.heroHome',
        //         start:'top top',
        //         end:'top -30%',
        //         scrub:true
        //     },
        //     opacity: 0,
        //     y:'10%',
        //     scale:0.9,
        //     ease:'none'
        // })

        gsap.to('.forme1', {
            scrollTrigger: {
                trigger:'.aimons',
                start:'top bottom',
                end:'bottom top',
                scrub:true
            },
            y: '400%',
            ease:'none'
        })

        gsap.to('.rect', {
            scrollTrigger: {
                trigger:'.aimons',
                start:'top bottom',
                end:'bottom top',
                scrub:true
            },
            y: '400%',
            rotate:46,
            ease:'none'
        })

        gsap.to('.manif svg', {
            scrollTrigger: {
                trigger:'.manif',
                start:'top bottom',
                end:'bottom top',
                scrub:true
            },
            rotate:180,
            ease:'none'
        })

        // gsap.to('.anim1', {
        //     scrollTrigger: {
        //         trigger:'.savoirFaire',
        //         start:'top bottom',
        //         end:'bottom top',
        //         scrub:true
        //     },
        //     y:'40%',
        //     ease:'none'
        // })
        // gsap.to('.anim2', {
        //     scrollTrigger: {
        //         trigger:'.savoirFaire',
        //         start:'top bottom',
        //         end:'bottom top',
        //         scrub:true
        //     },
        //     y:'20%',
        //     ease:'none'
        // })

        linter = setInterval(() => {
            let nextCit;
            if(document.querySelector('.innerC.current').nextElementSibling !== null){
                nextCit = document.querySelector('.innerC.current').nextElementSibling
            }else{
                nextCit = document.querySelectorAll('.innerC')[0]
            }
            gsap.to('.innerC.current', {
                duration:0.8,
                opacity:0,
                x:'-10%',
                ease:'power3.in',
                onComplete:() => {
                    document.querySelector('.innerC.current').classList.remove('current')
                    nextCit.classList.add('current')
                    gsap.fromTo(nextCit, {
                        x:'10%',
                        opacity:0
                    },{
                        duration:0.8,
                        delay:0.1,
                        opacity:1,
                        x:'0%',
                        ease:'power3.out'
                    })

                }
            })
        }, 4000)

    }else if(document.body.classList.contains('page-template-savoir-faire')){
        gsap.to('.capital1', {
            scrollTrigger: {
                trigger:'.capital1',
                start:'top bottom',
                end:'bottom top',
                scrub:true
            },
            x:'-20%',
            ease:'none'
        })
        gsap.to('.capital2', {
            scrollTrigger: {
                trigger:'.capital2',
                start:'top bottom',
                end:'bottom top',
                scrub:true
            },
            x:'5%',
            ease:'none'
        })
        gsap.to('.capital3', {
            scrollTrigger: {
                trigger:'.capital3',
                start:'top bottom',
                end:'bottom top',
                scrub:true
            },
            x:'-10%',
            ease:'none'
        })
    }else if(document.body.classList.contains('single-projets')){
        gsap.to('.single1 .lec', {
            scrollTrigger: {
                trigger:'.innerHero',
                start:'bottom bottom',
                endTrigger:'.innerPhraseSingle',
                end:'top top',
                scrub:true
            },
            y:'200%',
            ease:'none'
        })
    }else if(document.body.classList.contains('page-template-contact')){
        gsap.to('.contact1', {
            scrollTrigger: {
                trigger:'.innerHero',
                start:'top top',
                endTrigger:'.form',
                end:'bottom top',
                scrub:true
            },
            y:'70%',
            ease:'none'
        })
    }else if(document.body.classList.contains('post-type-archive')){
        document.querySelectorAll('.projets a').forEach(proj => {
            gsap.to(proj, {
                scrollTrigger: {
                    trigger:proj,
                    start:'top 90%',
                },
                opacity:1,
                duration:0.5,
                ease:'none'
            })
        })
    }else if(document.body.classList.contains('page-template-about')){
        monAnim = Lottie.loadAnimation({
            container: document.getElementById('lottie'), // the dom element that will contain the animation
            renderer: 'svg',
            autoplay: false,
            path: document.getElementById('lottie').getAttribute('data-url') // the path to the animation json
        });
        monAnim.addEventListener('DOMLoaded', waveReady);
    }
}


let monAnim;
function waveReady(){
    ScrollTrigger.create({
        trigger:'#lottie',
        start:'top bottom',
        end:'bottom top',
        onUpdate: (self) => {
            //nb frame -> 240
            const val = Math.round(gsap.utils.mapRange(0, 1, 1, 240, self.progress));
            monAnim.goToAndStop(val, true);
        }
    });
    ScrollTrigger.refresh();
    // scroll.resize();
}





function initMagnetic() {
if(isMobile() || window.innerWidth<=1024) return;
Array.prototype.forEach.call(document.querySelectorAll('.magnetic'), function(el, i){
var hover = false;
var offsetHoverMax = el.getAttribute("offset-hover-max") || 1;
var offsetHoverMin = el.getAttribute("offset-hover-min") || 0.75;

var attachEventsListener = function() {
window.addEventListener("mousemove", function(e) {
var hoverArea = hover ? offsetHoverMax : offsetHoverMin;
var cursor = { x: e.clientX, y: e.clientY + document.body.scrollTop }; // $(window).scrollTop()
var width = el.offsetWidth;
var height = el.offsetHeight;
var offset = el.getBoundingClientRect();
var elPos = { x: offset.left + width / 2, y: offset.top + height / 2 };
var x = cursor.x - elPos.x;
var y = cursor.y - elPos.y;
var dist = Math.sqrt(x * x + y * y);
var mutHover = false;
if(dist < width * hoverArea) {
mutHover = true;
if(!hover) hover = true;
onHover(x, y);
}
if(!mutHover && hover) { onLeave(); hover = false; }
});
};

var onHover = function(x, y) {
    gsap.to(el, { duration: 0.4, x: x * 0.8, y: y * 0.8, rotation: x * 0.05, ease: 'power2.out' });
};
var onLeave = function() {
    gsap.to(el, { duration: 0.7, x: 0, y: 0, scale: 1, rotation: 0, ease: 'elastic.out' });
};

attachEventsListener();
});
}