import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import GScroll from "GScroll";


import Transition from "modules/Transition";
import {indexInParent, isMobile} from "modules/utils.js";

gsap.registerPlugin(ScrollTrigger);
window.firstCall = true;

let animEnCours = false, zIndex = 0;

// document.addEventListener('keypress', logKey);
// function logKey(e) {
//     if(e.code=='Space') {
//         //e.preventDefault();
//         if(document.querySelector('body').classList.contains('dev')) document.querySelector('body').classList.remove('dev');
//         else document.querySelector('body').classList.add('dev');
//     }
// }

const transition = new Transition();
window.addEventListener("DOMContentLoaded", () => {

	// let scroller;
	// if(!isMobile()){
	//     scroller = document.getElementById('garciScroll')
	//     ScrollTrigger.defaults({
	//         scroller: scroller
	//     })
	// }

	// if(!isMobile()){
 //        ScrollTrigger.scrollerProxy(scroller, {
 //            scrollTop(value) {
 //                if (arguments.length) {
 //                    window.scroll.current = -value // setter
 //                }
 //                return -window.scroll.current // getter
 //            },
 //            getBoundingClientRect() {
 //                return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight}
 //            }
 //        });
 //    }

 //    if(!isMobile()){

	//     window.scroll = new GScroll('#garciScroll', 0.6, () => {ScrollTrigger.update()} );
	// 	window.scroll.init();
	// 	window.scroll.wheel();
	// }

	window.addEventListener('popstate', (e) => {
		transition.start(location.href);

		gsap.to('header', {
    		duration:1,
    		ease:"power2.inOut",
    		opacity:0
    	})
	}, false);

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}

	// TEMP apres anim intro
    transition.start(window.location.href);

	window.addEventListener('resize', () => {
		// if(!isMobile()){
		// 	window.scroll.resize();
		// }
	})


	document.addEventListener('click', function (event) {
		if (event.target.closest('.openMenu')) {
	    	console.log('to open')
	    	document.querySelector('.dr').classList.add('ouvert')
	    }else if (event.target.closest('.closeMenu')) {
	    	console.log('to close')

	    	document.querySelector('.dr').classList.remove('ouvert')
	    }else if (event.target.closest('a:not(.externe)') && !event.target.closest('.wrapSpe2')) {
	        event.preventDefault();
	        if(!transition.animEnCours){

	            transition.start(event.target.closest('a').getAttribute('href'));
	            history.pushState({}, '', event.target.closest('a').getAttribute('href'));

	            if(document.querySelector('.dr').classList.contains('ouvert')){
	            	gsap.set('#main', {opacity:0})
	            }

	            gsap.to('header', {
	        		duration:1,
            		ease:"power2.inOut",
            		opacity:0
	        	})

	        }

	        //if(event.target.closest('.lang-item')){
	        	
	        //}
	    }else if (event.target.closest('.filtre') && animEnCours == false) {

	    	animEnCours = true;
	    	document.querySelector('.filtre.current').classList.remove('current');

	    	// afficher tous les projets de ce filtre
			const filtre = event.target.closest('.filtre');
			filtre.classList.add('current')

	    	gsap.to('.projets', {
	    		opacity:0,
	    		duration:0.4,
	    		ease:'power2.inOut',
	    		onComplete:() => {

	    			let triggers = ScrollTrigger.getAll();
	                triggers.forEach( trigger => {
	                  trigger.kill();
	                });
	    			

	    			let i = 1;
			    	let iGlobal = 1;

			    	document.querySelectorAll('.proj').forEach(proj => {
			    		proj.style.display = "none";
			    		proj.classList.remove("actif", "p1","p2","p3","p4","p5","p6","p7","p8");
			    	})

			    	document.querySelectorAll('.'+filtre.getAttribute('data-slug')).forEach(proj => {
			    		proj.style.display = "block";
			    		proj.style.opacity = "0";
			    		proj.classList.add('actif', 'p'+i);
			    		//proj.querySelector('.iGlob').innerHTML = '0'+iGlobal;

			    		i++;
			    		iGlobal++;

				    	if(i > 8){
				    		i = 1;
				    	}
			    	})

			    	document.querySelectorAll('.projets a').forEach(proj => {
			            gsap.to(proj, {
			                scrollTrigger: {
			                    trigger:proj,
			                    start:'top 90%',
			                },
			                opacity:1,
			                duration:0.5,
			                ease:'none'
			            })
			        })
			        document.querySelectorAll('section[data-fond]').forEach(el => {
				        gsap.to('body', {
				            scrollTrigger: {
				                trigger:el,
				                start:'top 50%',
				                end:'top 0%',
				                scrub:true
				            },
				            backgroundColor: el.getAttribute('data-fond'),
				            ease:'none',
				            immediateRender:false
				        })
				    })

	
	    		}
	    	})

	    	gsap.to('.projets', {
	    		opacity:1,
	    		duration:0.4,
	    		ease:'power2.inOut',
	    		delay:0.5,
	    		onComplete: () => {
	    			animEnCours = false;

	    			
	    		}
	    	});

	    }else if((event.target.closest('.right') && animEnCours == false) || (event.target.closest('.left') && animEnCours == false)){
	    	
	    	if(!document.body.classList.contains('simul')){
	    		clearInterval(window.set);
	    	}
	    	document.body.classList.remove('simul');

	    	animEnCours = true;
	    	zIndex++;
	    	let currentEl = document.querySelector('.cover.current');

	    	let direct = 1;
	    	let nextEl = currentEl.parentNode.parentNode.nextElementSibling != null ? currentEl.parentNode.parentNode.nextElementSibling.querySelector('.cover') : document.querySelectorAll('.lesImgs img')[0];

	    	if(event.target.closest('.left')){
	    		direct = -1;
	    		nextEl = currentEl.parentNode.parentNode.previousElementSibling != null ? currentEl.parentNode.parentNode.previousElementSibling.querySelector('.cover') : document.querySelectorAll('.lesImgs img')[document.querySelectorAll('.lesImgs img').length - 1];
	    	}

	    	gsap.to('.currentSlide div', {
            	scaleX: 1 / document.querySelectorAll('.lesImgs img').length * (indexInParent(nextEl.parentNode.parentNode) + 1),
            	duration:1,
            	ease:'power3.inOut'
        	})

        	gsap.to('.currentS, .allPro', {
        		opacity:0,
        		duration:0.5,
        		onComplete: () => {
        			document.querySelector('.currentS span').innerHTML = indexInParent(nextEl.parentNode.parentNode) + 1;
        			document.querySelector('.allPro h5.current').classList.remove('current')
        			document.querySelectorAll('.allPro h5')[indexInParent(nextEl.parentNode.parentNode)].classList.add('current')
        			gsap.to('.currentS, .allPro', {
        				opacity:1,
        				duration:0.5
        			});
        		}
        	})

	    	// nextEl.style.zIndex = zIndex;
	    	nextEl.parentNode.style.zIndex = zIndex;
	    	
	    	gsap.fromTo(nextEl, {
	    		opacity:1,
	    		x: -direct*100 + '%',
	    		scale:1.04
	    	},{
	    		x:0,
	    		duration:1,
	    		scale:1,
	    		ease:'power3.inOut',
	    		onComplete:() => {
	    			currentEl.classList.remove('current')
	    			nextEl.classList.add('current')
	    			animEnCours = false
	    		}
	    	})
	    	gsap.fromTo(nextEl.parentNode, {
	    		x: direct*100 + '%'
	    	},{
	    		x:0,
	    		duration:1,
	    		ease:'power3.inOut'
	    	})

	    	// gsap.to(currentEl, {
	    	// 	x: -direct*5 + '%',
	    	// 	duration:1,
	    	// 	ease:'power3.inOut'
	    	// })
	    }else if (event.target.closest('#envoyer')) {

	    	event.preventDefault();

	    	if(document.querySelector('input[name="email"]').value !== "") {
		      document.getElementsByTagName('html')[0].classList.add('wait');
		      let formData;
		      formData =  'c_name='+encodeURIComponent(document.querySelector('input[name="prenom"]').value);
		      formData += '&c_adresse='+encodeURIComponent(document.querySelector('input[name="adresse"]').value);
		      formData += '&c_email='+encodeURIComponent(document.querySelector('input[name="email"]').value);
		      formData += '&c_tel='+encodeURIComponent(document.querySelector('input[name="tel"]').value);
		      formData += '&c_message='+encodeURIComponent(document.querySelector('textarea[name="message"]').value);
		      
		      const request = new XMLHttpRequest();
		      request.open('GET', '?alright&' + formData, true);
		      request.onload = () => { 
		      	if(request.status >= 200 && request.status < 400){
		      		gsap.to('.merci', {
		      			duration:1,
		      			opacity:1
		      		})
		      	}
		      }
		      request.send();
		    }
		    return false;
	    }
	})
	
	

})
